<template>
  <div class="Trainees">
    <EduHeader />
    <main class="TraineesMian">
      <div class="TraineesCententInfo">
        <div class="TraineesSelect">
          <div class="TraineesSelectTas">
            <dl>
              <dd class="cicon-1"></dd>
              <dd class="cicon-2 on"></dd>
            </dl>
          </div>
          <div class="Traineesserver">
            <input type="text">
          </div>
        </div>
        <div class="TraineesCentent">
          <dl>
            <dd @click="infoShowFn()">
              <div><img src="~@/assets/Trainees/img1.png"></div>
              <div class="ddTitle">
                <span>欧阳明明</span>
                <i>上海</i>
              </div>
            </dd>
            <dd>
              <div><img src="~@/assets/Trainees/img1.png"></div>
              <div class="ddTitle">
                <span>欧阳明明</span>
                <i>上海</i>
              </div>
            </dd>
            <dd>
              <div><img src="~@/assets/Trainees/img1.png"></div>
              <div class="ddTitle">
                <span>欧阳明明</span>
                <i>上海</i>
              </div>
            </dd>
            <dd>
              <div><img src="~@/assets/Trainees/img1.png"></div>
              <div class="ddTitle">
                <span>欧阳明明</span>
                <i>上海</i>
              </div>
            </dd>
            <dd>
              <div><img src="~@/assets/Trainees/img1.png"></div>
              <div class="ddTitle">
                <span>欧阳明明</span>
                <i>上海</i>
              </div>
            </dd>
            <dd>
              <div><img src="~@/assets/Trainees/img1.png"></div>
              <div class="ddTitle">
                <span>欧阳明明</span>
                <i>上海</i>
              </div>
            </dd>
          </dl>
        </div>
      </div>
      <div class="itemInfo">
        <dl>
          <dd>赵</dd>
          <dd>蒋</dd>
          <dd>孔</dd>
          <dd>柏</dd>
          <dd>鲁</dd>
          <dd>酆</dd>
          <dd>滕</dd>
          <dd>皮</dd>
          <dd>和</dd>
          <dd>米</dd>
          <dd>熊</dd>
          <dd>席</dd>
          <dd>梅</dd>
          <dd>樊</dd>
          <dd>谈</dd>
          <dd>杜</dd>
        </dl>
      </div>
      <div class="infoShow" v-if="infoShow">
        <div class="infoDInfo">
          <div class="gb1" @click="gbInfoShowFn"><img src="~@/assets/Trainees/gb1.png"></div>
          <div class="infoDInfo-item">
            <div class="infoDInfo-item-img">
              <img src="~@/assets/Trainees/31475-1.png">
            </div>
            <div class="infoDInfo-item-font">
              <div class="infoDInfo-item-fontTitle">欧阳明明</div>
              <div class="infoDInfo-item-icon">
                <div class="icon-1">上海</div>
                <div class="icon-2" @click="infoShowDFn()"><img src="~@/assets/Trainees/icon3a.png"></div>
              </div>
            </div>
          </div>
          <div class="introduce">
            <div class="introduceTitle">
              <span>关于欧阳明明</span>
            </div>
            <div class="introduceTitle1">基本信息</div>
            <div class="introduceFont">我的人生目标是帮助我们在我们的星球上实现可持续发展。目前，我是母亲路酿造公司的人与文化迪尔 - 亚利桑那州第三大独立工艺啤酒厂。我们一次建一品脱社区。跑步，露营，做饭。</div>
            <div class="introduceTitle1">教育</div>
            <div class="introduceFont">北亚利桑那大学<br>管理， 业务 » - 出席<br>埃姆 · 斯特拉斯堡<br>管理 [伊拉斯谟]， 业务 + - 存在</div>
            <div class="introduceTitle1">组织</div>
            <div class="introduceFont">母亲路酿造有限公司<br>公司服务部人物与文化 » - 出席<br>亚利桑那前锋<br>执行官、董事会成员 • - 出席</div>
            <div class="introduceTitle1">华路文化在线编号</div>
            <div class="introduceFont">202121545512122</div>
            <div class="introduceTitle">
              <span>关于欧阳明明的有趣的事情</span>
            </div>
            <div class="introduceDl">
              <dl>
                <dd>
                  <div class="divItem"></div>
                  <div class="divImg"></div>
                  <div class="divFont">如果您可以住在世界任何地方，那会在哪里？</div>
                </dd>
              </dl>
            </div>
            <div class="introduceBut">
              <div class="but1">发送消息</div>
              <div class="but2">阻止用户</div>
            </div>
          </div>
        </div>
      </div>
      <div class="infoShowD" v-if="infoShowD">
        <div class="infoShowDInfo">
          <div class="gb2" @click="gbInfoShowDFn"><img src="~@/assets/Trainees/gb1.png"></div>
          <div class="infoShowDInfoTitle">
            账户资料
          </div>
          <div class="fontTitle">基本信息</div>
          <div class="fontTable">
            <table border="0" style="width:100%">
              <tr>
                <td>
                  <div class="fontTableTitle">电话</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
                <td>
                  <div class="fontTableTitle">城市</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="fontTableTitle">微信</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div class="fontTableTitle">邮箱</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
                <td></td>
              </tr>
            </table>
          </div>
          <div class="fontTitle">教育背景</div>
          <div class="fontTable">
            <table border="0" style="width:100%">
              <tr>
                <td>
                  <div class="fontTableTitle">最高学历</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
                <td></td>
              </tr>
              <tr>
                <td>
                  <div class="fontTableTitle">毕业院校</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
                <td>
                  <div class="fontTableTitle">毕业专业</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="fontTitle">单位职务</div>
          <div class="fontTable">
            <table border="0" style="width:100%">
              <tr>
                <td>
                  <div class="fontTableTitle">单位名称</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
                <td>
                  <div class="fontTableTitle">职位</div>
                  <div class="fontTableFont">
                    <input type="text" />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="fontTableBut">
            <button class="but1" type="button">取消</button>
            <button class="but2" type="button">提交</button>
          </div>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import EduHeader from "@/components/EduHeader";
export default {
  name: "Trainees",
  components: {
    EduHeader
  },
  data() {
    return {
      infoShow: false,
      infoShowD: false
    };
  },
  created() {
  },
  methods: {
    infoShowFn(){
      this.infoShow = true
    },
    gbInfoShowFn(){
      this.infoShow = false
    },
    infoShowDFn(){
      this.infoShowD = true
    },
    gbInfoShowDFn(){
      this.infoShowD = false
    }
  }
};
</script>

<style lang="scss" scoped>
.TraineesMian {
  min-height: 100vh;
  position: relative;
  display: block;
  .TraineesCententInfo{
    padding-top: 60px;
    .TraineesSelect{
      background-color: #FFFFFF;
      border-bottom: 1px solid #707070;
      padding: 20px 40px 20px 40px;
      .TraineesSelectTas{
        float: left;
        background-color: #737373;
        border: 1px solid #707070;
        border-radius: 6px;
        overflow: hidden;
        dl{
          dd{
            float: left;
            height: 45px;
            width: 45px;
            cursor: pointer;
            text-align: center;
            background-position: center;
            background-repeat: no-repeat;
            &.on{
              background-color: #ffffff;
            }
            &.cicon-1{
              background-image: url('~@/assets/Trainees/icon1.png');
            }
            &.cicon-1.on{
              background-image: url('~@/assets/Trainees/icon1a.png');
            }
            &.cicon-2{
              background-image: url('~@/assets/Trainees/icon2.png');
            }
            &.cicon-2.on{
              background-image: url('~@/assets/Trainees/icon2a.png');
            }
          }
        }
      }
      .Traineesserver{
        margin-left: 10px;
        float: left;
        input{
          background: url("~@/assets/Trainees/server.png") no-repeat 10px center;
          padding: 0 14px 0 35px;
          height: 45px;
          border: 1px solid #707070;
          border-radius: 6px;
          font-size: 16px;
          width: 500px;
        }
      }
      &::after{
        clear: both;
        display: block;
        content: '';
      }
    }
    .TraineesCentent{
      dl{
        padding: 20px;
        dd{
          float: left;
          background: #FFFFFF;
          border: 1px solid #A1A1A1;
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
          border-radius: 5px;
          margin: 15px 20px;
          padding: 15px;
          .ddTitle{
            height: 46px;
            font-size: 20px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            line-height: 36px;
            color: #7D7D7D;
            span{
              float: left;
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7D7D7D;
            }
            i{
              float:right;
              font-size: 16px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              color: #7D7D7D;
              font-style: normal;
            }
          }
        }
      }
      &::after{
        clear: both;
        display: block;
        content: '';
      }
    }
  }
  .itemInfo{
    position: fixed;
    right: 20px;
    top: 200px;
    dl{
      dd{
        line-height: 30px;
        cursor: pointer;
      }
    }
  }
  .infoShow{
    position: fixed;
    z-index: 100;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    padding: 35px;
    overflow: auto;
    .infoDInfo{
      width: 700px;
      background: #FFFFFF;
      opacity: 1;
      border-radius: 8px;
      margin: 0 auto;
      padding: 35px 50px;
      position: relative;
      margin: 0 auto;
      .gb1{
        position: absolute;
        right:20px;
        top: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        img{
          width: 24px;
          height: 24px;
        }
      }
    }
    .infoDInfo-item{
      .infoDInfo-item-img{
        float: left;
        width: 132px;
        height: 132px;
        img{
          width: 132px;
          height: 132px;
        }
      }
      .infoDInfo-item-font{
        padding-left: 150px;
        .infoDInfo-item-fontTitle{
          font-size: 28px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #173D7C;
        }
        .infoDInfo-item-icon{
          margin-top: 15px;
          .icon-1{
            float: left;
            margin-right: 30px;
            background: url("~@/assets/Trainees/icon2a.png") no-repeat left;
            padding-left:30px;
            line-height: 30px;
          }
          .icon-2{
            float: left;
            cursor: pointer;
            img{
              width: 24px ;
            }
          }
        }
      }
      &::after{
        clear: both;
        display: block;
        content: '';
      }
    }
    .introduce{
      .introduceTitle{
        height: 13px;
        box-sizing: border-box;
        margin-bottom: 12px;
        border-bottom: 1px solid #707070;
        text-align: center;
        margin-top: 20px;
        span{
          display: inline-block;
          padding: 0 20px;
          background: #ffffff;
        }
      }
      .introduceTitle1{
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 24px;
        color: #303030;
        margin-top: 15px;
      }
      .introduceFont{
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 20px;
        color: #303030;
        margin-top: 10px;
      }
      .introduceDl{
        dl{
          dd{
            position: relative;
            text-align: center;
            margin-top: 70px;
            .divImg{
              height: 280px;
            }
            .divItem{
              width: 82px;
              height: 82px;
              background: #FFFFFF;
              border: 1px solid #707070;
              border-radius: 50%;
              opacity: 1;
              margin: 0;
              position: absolute;
              z-index: 1;
              left: 50%;
              margin-left: -41px;
              top: -41px;
            }
            .divFont{
              font-size: 20px;
              font-family: Microsoft YaHei;
              font-weight: 400;
              line-height: 24px;
              color: #666666;
              opacity: 1;
              margin-top: 15px;
            }
          }
        }
      }
      .introduceBut{
        margin-top: 35px;
        height: 44px;
        text-align: center;
        .but1{
          border-radius: 6px;
          width: 127px;
          height: 44px;
          border: 1px solid #707070;
          opacity: 1;
          float: left;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 44px;
          color: #4D4D4D;
          cursor: pointer;
        }
        .but2{
          border-radius: 6px;
          width: 127px;
          height: 44px;
          border: 1px solid #707070;
          opacity: 1;
          float: right;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 44px;
          color: #BBBBBB;
          cursor: pointer;
        }
      }
    }
  }
  .infoShowD{
    position: fixed;
    z-index: 101;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    left: 0;
    top: 0;
    padding: 35px;
    overflow: auto;
    .infoShowDInfo{
      color: #ffffff;
      width: 738px;
      background: #444444;
      opacity: 1;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 50px 90px;
      margin: 0 auto;
      position: relative;
      .gb2{
        position: absolute;
        right:20px;
        top: 20px;
        width: 24px;
        height: 24px;
        cursor: pointer;
        img{
          width: 24px;
          height: 24px;
        }
      }
      .infoShowDInfoTitle{
        font-size: 28px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 50px;
      }
      .fontTitle{
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        line-height: 50px;
        color: #FFFFFF;
        border-bottom: 1px dashed #ffffff;
        margin-top: 15px;
      }
      .fontTable{
        margin: 0 -10px;
        td{
          padding-top: 10px;
          width: 50%;
          box-sizing: border-box;
          padding: 10px 10px 0px 10px;
        }
        .fontTableTitle{
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          line-height: 36px;
          color: #FFFFFF;
        }
        .fontTableFont{
          input{
            height: 45px;
            border: 1px solid #828282;
            padding: 0 14px;
            border-radius: 6px;
            background: transparent;
            width: 100%;
            color: #ffffff;
          }
        }
      }
      .fontTableBut{
        margin-top: 40px;
        padding-bottom: 50px;
        .but1{
          height: 44px;
          background: #5A5A5D;
          border-radius: 8px;
          padding: 0 20px;
          border: 0;
          cursor: pointer;
          float: left;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #FFFFFF;
          opacity: 1;
          margin-right: 12px;
        }
        .but2{
          height: 44px;
          background: #FFCF00;
          border-radius: 8px;
          padding: 0 20px;
          border: 0;
          cursor: pointer;
          float: left;
          font-size: 16px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #333333;
          opacity: 1;
          margin-right: 12px;
        }
      }
    }
  }
}
</style>
